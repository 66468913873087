<!--学员考试成绩编辑  -->
<template>
    <div class="EditTestResult">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学员考试成绩编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" id="form-1" :rules="rules" class="" inline label-width="150px">
            <el-form-item label="登记单号">
                <el-input v-model="form.number" placeholder="请输入登记单号" disabled></el-input>
            </el-form-item>
            <el-form-item label="机构名称">
                <el-select v-model="form.name" filterable placeholder="请输入并选择机构名称" :clearable="true">
                    <el-option v-for="item in form.nameOpt" :key="item.id" :label="item.name" :value="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="准考证">
                <el-input v-model="form.identity" placeholder="请输入准考证" disabled></el-input>
            </el-form-item>
            <el-form-item label="学员姓名" prop="studentName">
                <el-select v-model="form.studentName" filterable remote placeholder="请输入并选择学员姓名"
                 :clearable="true" :remote-method="students" @change="studentChange">
                    <el-option v-for="item in form.studentNameOpt" :key="item.id" :label="item.username"
                        :value="item.username">
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="指导老师">
                <el-input v-model="form.instructor" placeholder="请输入指导老师"></el-input>
            </el-form-item> -->
            <el-form-item label="联系电话">
                <el-input v-model="form.telephone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="申请考试日期">
                <el-date-picker v-model="form.applytestDate" type="date" placeholder="请选择考试日期">
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="下单人员">
                <el-input v-model="form.nextPerson" placeholder="请输入下单人员"></el-input>
            </el-form-item> -->
            <el-form-item label="报考日期">
                <el-date-picker v-model="form.enrollDate" 
                    value-format="yyyy-MM-dd" type="date" placeholder="请选择报考日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="申请大类">
                <el-select v-model="form.bigclass" filterable placeholder="请输入并选择申请大类"
                    :clearable="true" @change="applyChange" >
                    <el-option v-for="item in form.bigclassOpt" :key="item.id" :label="item.exam_name"
                        :value="item.exam_name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择科目">
                <el-select v-model="form.subject" filterable placeholder="请输入并选择科目"
                    :clearable="true"   @change="subjectChange">
                    <el-option v-for="item in form.subjectOpt" :key="item.id" :label="item.course_name"
                        :value="item.course_name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="原等级值">
                <el-select v-model="form.originalGrade" placeholder="请选择原等级值"
                    :clearable="true">
                    <el-option v-for="item in form.originalGradeOpt" :key="item.id" :label="item.level"
                        :value="item.level">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="考试日期">
                <el-date-picker v-model="form.testDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择报考日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="考试开始时间">
                <el-time-picker
                   v-model="form.startTime"
                   :picker-options="{
                     selectableRange: '00:00:00 - 23:59:59'
                   }"
                   placeholder="请选择开始时间">
                 </el-time-picker>
            </el-form-item>
            <el-form-item label="截止时间">
                <el-time-picker
                   v-model="form.endTime"
                   :picker-options="{
                     selectableRange: '00:00:00 - 23:59:59'
                   }"
                   placeholder="请选择截止时间">
                 </el-time-picker>
            </el-form-item>
            <el-form-item label="考试时长">
                <el-input v-model="form.duration" placeholder="请输入考试时长" 
                onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="副证费">
                <el-input v-model="form.certificateFee" placeholder="请输入副证费" 
                onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="考级报名费">
                <el-input v-model="form.registrationFee" placeholder="请输入考级报名费"
                 onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="考级管理费">
                <el-input v-model="form.manageFee" placeholder="请输入考级管理费"
                 onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="应收费金额">
                <el-input v-model="form.receivable" placeholder="请输入应收费金额"
                 onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="实收金额">
                <el-input v-model="form.netReceipts" placeholder="请输入实收金额" 
                onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="考试分数">
                <el-input v-model="form.fraction" placeholder="请输入考试分数" 
                onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="选择等级">
                <el-select v-model="form.grade" placeholder="请选择等级"
                    :clearable="true">
                    <el-option v-for="item in form.gradeOpt" :key="item.id" :label="item.level"
                        :value="item.level">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="做副证">
               <div style="width: 300px;">
                    <el-radio-group v-model="form.whether">
                        <el-radio label="1">是</el-radio>
                        <el-radio label="2">否</el-radio>
                    </el-radio-group>
                </div>
            </el-form-item>
            <el-form-item label="考试备注">
                <el-input v-model="form.remarks" class="text-ipt" placeholder="请输入考试备注">
                </el-input>
            </el-form-item>
            <el-form-item label="考试结论">
                <div style="width: 300px;">
                    <el-radio-group v-model="form.epilog">
                        <el-radio label="1">未录入</el-radio>
                        <el-radio label="2">通过</el-radio>
                        <el-radio label="3">不通过</el-radio>
                    </el-radio-group>
                </div>
            </el-form-item>
           <el-form-item label="状态">
               <div style="width: 757px;">
                   <el-radio-group v-model="form.condition">
                       <el-radio :label="1">草稿</el-radio>
                       <el-radio :label="2">报考</el-radio>
                       <el-radio :label="3">准考</el-radio>
                       <el-radio :label="4">考试OK</el-radio>
                       <el-radio :label="5">取消</el-radio>
                       <el-radio :label="6">退款取消</el-radio>
                       <el-radio :label="7">延期</el-radio>
                       <el-radio :label="8">缺考</el-radio>
                   </el-radio-group>
               </div>
           </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;"  @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               form:{
                  number:"",//登记单号
                  name:"",//机构名称
                  name_id: 0, //机构id
                  nameOpt: [], //机构名称选项
                  identity:"",//准考证
                  studentName:"",//学员姓名
                  studentName_id: "", //学员id
                  studentNameOpt: [], //学员姓名选项
                  idcard: "", //学员身份证
                  instructor:"",//指导老师
                  telephone:"",//联系电话
                  applytestDate:"",//申请考试日期
                  nextPerson:"",//下单人员
                  enrollDate:"",//报考日期
                  bigclass: '', //申请大类
                  bigclass_id:0,//大类id
                  bigclassOpt:[],//考试大类选项
                  subject:"",//科目
                  subject_id:0,//科目id
                  subjectOpt: [], //科目选项
                  originalGrade:"",//原等级值
                  startTime:"",//考试开始时间
                  endTime:"",//截止时间
                  duration: "", //考试时长
                  certificateFee:"",//副证费
                  registrationFee:"",//考级报名费
                  manageFee:"",//考级管理费
                  receivable:"",//应收费金额
                  netReceipts:"",//实收金额
                  fraction:"",//考试分数
                  epilog:"",//考试结论
                  grade:"",//选择等级
                  whether:"",//是否做副证
                  remarks:"",//考试备注
                  condition:"",//状态 
               },
               rules: {
                   studentName: [{
                       required: true,
                       message: '学员姓名不能为空',
                       trigger: 'blur'
                   }],
                   
               },
               bool:false
            }
        },
        created() {
            //机构名称数据
            this.$request({
                url: "/api/mechanism/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                // console.log("机构名称数据",res.data.list)
                this.form.nameOpt = res.data.list
            })
            
            //申请大类数据
            this.$request({
                url: "/api/ExamCategory/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 100
                }
            }).then(res => {
                console.log("申请大类数据",res.data.list)
                this.form.bigclassOpt = res.data.list
            })
            
            //学科数据
            this.$request({
                url: "/api/course/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 100
                }
            }).then(res => {
                // console.log("学科数据",res.data.list)
                this.form.subjectOpt = res.data.list
            })
            
            // 原等级与报考等级
            this.$request({
                url: "/api/level/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 100
                }
            }).then(res => {
                // console.log("原等级与报考等级",res.data.list)
                this.form.originalGradeOpt = res.data.list
                this.form.gradeOpt = res.data.list
                
            })
            
            // 初次渲染
            this.$request({
                url: "/api/examenroll/detail",
                method: "POST",
                data: {
                    id:this.$route.query.id
                }
            }).then(res => {
                if(res.code==1){
                    console.log(res,'初次渲染')
                    let data = res.data
                    let form = this.form
                    form.number=data.enroll_number//登记单号
                    form.name_id=data.org_id //机构ID
                    form.name=data.org_name//机构名称
                    form.identity=data.certificate_no//准考证号
                    form.studentName=data.name//学生名称
                    form.idcard=data.idcard//身份证
                    form.telephone=data.phone//联系电话
                    form.applytestDate=data.apply_date//申请考试时间
                    form.bigclass=data.apply//考试大类
                    form.bigclass_id=data.apply_category//考试大类id
                    form.subject_id=data.course_id//学科id
                    form.subject=data.course//学科
                    form.originalGrade=data.original_level//原等级
                    form.grade=data.exam_level//报考等级
                    form.startTime=data.exam_startdate//考试开始时间
                    form.endTime=data.exam_enddate//考试结束时间
                    form.duration=data.exam_duration//考试时长
                    form.certificateFee=data.certificatecopy_price//副证费
                    form.registrationFee=data.enroll_price//考级报名费
                    form.manageFee=data.manage_price//考级管理费
                    form.receivable=data.price1//应收费金额
                    form.netReceipts=data.price2//实收金额
                    form.remarks=data.remark// 考试备注
                    form.fraction=data.text_score// 考试分数
                    form.whether=data.is_deputy// 是否副证
                    form.epilog=data.exam_result// 考试结论
                    form.condition=data.status// 状态
                }
                
            })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            }, 
            submitForm(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(this.form)
                        if(this.bool){
                            return
                        }
                        this.bool=true
                        let form = this.form
                         this.$request({
                             url: "/api/examenroll/edit",
                             method: "POST",
                             data:{
                                id:this.$route.query.id,
                                enroll_number:form.number,//登记单号
                                org_id:form.name_id, //机构ID
                                org_name:form.name,//机构名称
                                certificate_no:form.identity,//准考证号
                                name:form.studentName,//学生名称
                                idcard:form.idcard,//身份证
                                phone:form.telephone,//联系电话
                                apply_date:form.applytestDate,//申请考试时间
                                apply_category:form.bigclass_id,//考试大类id
                                course_id:form.subject_id,//学科id
                                course:form.subject,//学科
                                original_level:form.originalGrade,//原等级
                                exam_level:form.grade,//报考等级
                                exam_startdate:form.startTime,//考试开始时间
                                exam_enddate:form.endTime,//考试结束时间
                                exam_duration:form.duration,//考试时长
                                certificatecopy_price:form.certificateFee,//副证费
                                enroll_price:form.registrationFee,//考级报名费
                                manage_price:form.manageFee,//考级管理费
                                price1:form.receivable,//应收费金额
                                price2:form.netReceipts,//实收金额
                                remark:form.remarks,// 考试备注
                                text_score:form.fraction,// 考试分数
                                is_deputy:form.whether,// 是否副证
                                exam_result:form.epilog,// 考试结论
                                status:form.condition// 状态
                             }
                         }).then(res=>{
                             if(res.code==1){
                                 this.$message({
                                   message: '编辑成功',
                                   type: 'success'
                                 })
                                 setTimeout(() => {
                                 	this.goBack()	
                                 }, 1500)
                             }else{
                                this.$message({
                                  message: res.msg,
                                  type: 'error'
                                })
                                setTimeout(() => {
                                	this.goBack()	
                                }, 1500)
                             }
                         })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) {//取消
                this.goBack()
                this.$refs[form].resetFields();
            },
            
            //学生数据
            students(e) {
                // console.log(e);
                this.$request({
                    url: "/api/student/list",
                    method: "POST",
                    data: {
                        page: 1,
                        limit: 100,
                        key: e
                    }
                }).then(res => {
                    // console.log("下单人员数据", res.data.list)
                    this.form.studentNameOpt = res.data.list
                })
            },
            studentChange(e){
                // console.log(e)
                let result = this.form.studentNameOpt.find(item => item.username == e)
                this.form.studentName_id = result.id
                this.form.idcard = result.idcard
                this.form.telephone=result.mobile1
                // console.log(result.id,result.idcard)
            },
            
            // 学科数据
            subjectChange(e){
                let result = this.form.subjectOpt.find(item => item.course_name == e)
                this.form.subject_id=result.id
                console.log(result.id)
            },
            
            //考试大类
            applyChange(e){
                let result = this.form.bigclassOpt.find(item => item.exam_name == e)
                this.form.bigclass_id=result.id
                console.log(result.id)
            }
        }
    }
</script>

<style scoped="scoped">
    .EditTestResult{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 39px 28px;
        display: flex;
        justify-content: space-between;
    }
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    #form-1 ::v-deep .el-input.is-disabled .el-input__inner{
        background-color: #fff;
    }
    /* 单选按钮 */
     #form-1 ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: #18BC9C;
    
    }
    
    #form-1 ::v-deep.el-radio__input.is-checked+.el-radio__label {
        color: #18BC9C;
    }
    
    #form-1 ::v-deep .el-radio__inner:hover {
        border-color: #18BC9C;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
